<!--  -->
<template>
  <div
    class="
      home
      page-template
      page-template-template-home
      page-template-template-home-php
      page
      page-id-11
      wpb-js-composer
      js-comp-ver-6.4.2
      vc_responsive
    "
  >
    <Headers></Headers>
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="main" class="site-main" role="main">
          <div class="content_container subsidiary">
            <!-- 头部banner图 -->
            <div class="section_banner_container" style="margin-bottom: 10px">
              <div class="section_banner">
                <div class="container">
                  <div class="row">
                    <div class="col-xs-12 col-sm-11">
                      <div class="section_banner_content">
                        <h1 class="section_banner_heading">集团子公司</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 内容 -->
            <div class="inside-page container">
              <div class="commonweb">
                <div class="organization">
                  <div class="org-left">
                    <div class="accordion-main">
                      <h4 class="accordion-titile" @click="toggle()">
                        集团子公司
                      </h4>
                      <div class="accordion">
                        <ul>
                          <li>
                            <h4 class="title">
                              <a style="color: #000">盈喜集团子公司</a>
                            </h4>

                            <div class="submain">
                              <a
                                v-for="(item, index) in navList"
                                :key="index"
                                :class="navSelect === index ? 'cur' : ''"
                                @click="selectSub(index, item.code, $event)"
                                >{{ item.name }}</a
                              >
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- 内容 -->
                  <div
                    class="org-right"
                    style="text-indent: 2em; line-height: 2em; font-size: 16px"
                    v-html="content"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <!-- #content -->
    <Footers></Footers>
  </div>
</template>

<script>
import Headers from "../../components/header/header.vue";
import Footers from "../../components/footer/footer.vue";
import { queryPage } from "../../util/api.js";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { Headers, Footers },
  metaInfo() {
    return {
      title: this.seoContent.seoTitle + "- 盈喜集团股份有限公司|ATXG",
      meta: [
        { name: "keywords", content: this.seoContent.seoKeywords },
        {
          name: "description",
          content: this.seoContent.seoDescription,
        },
      ],
    };
  },
  data() {
    //这里存放数据
    return {
      // 菜单选项
      // navList: [
      //   '东莞市大莹服装批发有限公司',
      //   '深圳市盈喜鹏发物流有限公司',
      //   '深圳市新快捷运输有限公司',
      //   '汕头市佰伊服饰有限公司',
      //   '东莞市恒圣威服装有限公司',
      //   '东莞誉尚服饰有限公司'
      // ],
      navList: [
        { name: "东莞市泓翔商业有限公司", code: "daying" },
        { name: "深圳市盈喜鹏发物流有限公司", code: "huapengfa" },
        { name: "深圳市新快捷运输有限公司", code: "xinkuaijie" },
        { name: "汕头市佰伊服饰有限公司", code: "daitou" },
        { name: "东莞市恒圣威服装有限公司", code: "hengsheng" },
        { name: "东莞誉尚服饰有限公司", code: "yushang" },
      ],
      // 当前所选项
      navSelect: 0,
      // 当前子公司url
      code: "daying",
      // 内容
      content: "",
      seoContent: {},
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //提取字符串
    takeStr(str) {
      str = str.replace(/\<[^>]*\>(([^<])*)/g, function () {
        let mark = "";
        return arguments[1];
      });
      str = str.substring(0, 120);
      return str;
    },
    // 初始化
    async init(code) {
      const { data: res } = await this.$http.get(queryPage, {
        params: { code: code },
      });
      console.log(res);
      this.seoContent = res.result;
      this.content = res.result.content;
      // 判断是哪个子公司
      var index = this.navList.findIndex((item) => item.code === code);
      // console.log(index)
      this.navSelect = index;
    },
    // 选择子公司
    selectSub(id, code, e) {
      this.navSelect = id;
      // this.code = code
      // console.log(code)
      this.init(code);
      if (document.documentElement.clientWidth < 1024) {
        let target = e.target;
        $(target).parents(".accordion").slideToggle();
      }
    },
    // 下拉
    toggle() {
      $(".accordion-titile").siblings(".accordion").slideToggle();
    },
    // 刷新回到顶部
    scrollTop() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // this.init(this.$route.query.id);
    this.init("daitou");
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  watch: {
    $route(to, from) {
      if (to.query) {
        // this.init(this.$route.query.id);
        this.init("daitou");

        this.scrollTop();
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import url("./../../../public/woer/layout.css");
.subsidiary {
  text-align: left;
  .section_banner {
    background-image: url(../../../public/home/img/bg-section-banner-contact.jpg);
  }
}
</style>
